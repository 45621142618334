import * as ton3 from "ton3-core";
const LUMP_PRICE = 1000000;
const BIT_PRICE = 65536000;
const CELL_PRICE = 6553600000;
const AVG_COMPUTE_FEE = 50000000;
const COMPUTE_FEE_LIMIT = 100000000;

export async function getTransactionFee(messages: ton3.Cell[], storage: number) {
  if (messages.length < 1) {
    return 0;
  }
  const queue = [...messages[0].refs];
  let bitAmount = 0;
  let cellAmount = 0;
  while (queue.length) {
    const vertex = queue.pop();

    queue.push(...vertex!.refs);
    bitAmount += vertex!.bits.length;
    cellAmount += 1;
  }
  const inFwdFee = LUMP_PRICE + (bitAmount * BIT_PRICE + cellAmount * CELL_PRICE) / Math.pow(2, 16);
  const fee = inFwdFee + storage + AVG_COMPUTE_FEE;
  return fee;
}
