import { useAppSelector } from "@/store";
import { selectAddressBookByChain } from "@/store/addressBookSlice";
import { supportedChain } from "@/config/chains";

const useAddressBook = () => {
  const { chainId } = supportedChain;

  return useAppSelector((state) => selectAddressBookByChain(state, chainId));
};

export default useAddressBook;
