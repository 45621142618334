import { type ReactElement } from "react";
import { EthHashInfo } from "@safe-global/safe-react-components";
import type { EthHashInfoProps } from "@safe-global/safe-react-components";
import { supportedChain } from "@/config/chains";

const PrefixedEthHashInfo = ({
  showName = true,
  hasExplorer = false,
  isTx = false,
  ...props
}: EthHashInfoProps & { showName?: boolean; isTx?: boolean }): ReactElement => {
  const name = showName ? props.name : undefined;
  const explorerType = isTx ? "tx" : "address";

  return (
    <EthHashInfo
      {...props}
      name={name}
      hasExplorer={hasExplorer}
      ExplorerButtonProps={{
        title: "View on explorer",
        href: `${supportedChain?.explorerUrl}/${explorerType}/${[props.address]}`,
      }}
    >
      {props.children}
    </EthHashInfo>
  );
};

export default PrefixedEthHashInfo;
