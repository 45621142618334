import { supportedChain } from "@/config/chains";
import { useWallet } from "@/hooks";
import { useWalletsByOwnerAddress } from "tonkey-gateway-typescript-sdk";
import { SafeInfo } from "@/types/safeInfo";
import { toRawAddress, toUserFriendlyAddress } from "@/utils/addresses";
import { CHAIN } from "@tonconnect/protocol";
import { useEffect, useMemo, useState } from "react";

export type OwnedSafes = { [chainId in CHAIN]: string[] };

const defaultOwnedSafes: OwnedSafes = {
  [CHAIN.MAINNET]: [],
  [CHAIN.TESTNET]: [],
};

const ownedSafesCache: OwnedSafes = {
  [CHAIN.MAINNET]: [],
  [CHAIN.TESTNET]: [],
};

const useOwnedSafes = (): OwnedSafes => {
  const { address } = useWallet() || {};
  const { safeList, loading, error, refetch } = useWalletsByOwnerAddress(address ?? "");
  const [initailized, setInitialized] = useState(false);

  const ownedSafes = useMemo(() => {
    if (!loading && safeList) {
      const _ownedSafes = ownedSafesCache;

      if (!address) return defaultOwnedSafes;

      if (safeList.length === 0) {
        return defaultOwnedSafes;
      }

      if (safeList.length > 0) {
        _ownedSafes[supportedChain.chainId] = safeList
          .filter((safes: SafeInfo) => safes.chainId === supportedChain.chainId)
          .map((safe) => toUserFriendlyAddress(safe.address));
      }

      setInitialized(true);
      return _ownedSafes;
    }
  }, [address, loading, safeList]);

  useEffect(() => {
    if (initailized) {
      const rawAddress = address ? toRawAddress(address) : "";
      refetch({ ownerAddress: rawAddress });
    }
  }, [address, initailized, refetch]);

  return error ? defaultOwnedSafes : ownedSafes ?? defaultOwnedSafes;
};

export default useOwnedSafes;
