export type Transaction = {
  summary: TransactionSummary;
  details?: TransactionDetails;
};

// off chain information
export type TransactionSummary = {
  createdAt: number;
  status: StatusType;
  transactionInfo: TransactionInfo;
  multiSigExecutionInfo?: MultiSigExecutionInfo;
};

export enum StatusType {
  UNKNOWN = "UNKNOWN",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  AWAITING_CONFIRMATIONS = "AWAITING_CONFIRMATIONS",
  AWAITING_EXECUTION = "AWAITING_EXECUTION",
}

export type TransactionInfo = Transfer | Creation | Cancellation;

export type Transfer = {
  transactionType: TransactionType.TRANSFER;
  sender: string;
  recipient: string;
  direction: DirectionType;
  transferInfo: TransferInfo;
};

export enum TransactionType {
  TRANSFER = "Transfer",
  CREATION = "Creation",
  CANCELLATION = "Cancellation",
}

export enum DirectionType {
  UNKNOWN = "UNKNOWN",
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export type TransferInfo = NativeTransferInfo | FTTransferInfo | NFTTransferInfo;

export type NativeTransferInfo = {
  transferType: TransferType.NATIVE_TRANSFER;

  // unit is gram
  value: string;
};

export enum TransferType {
  NATIVE_TRANSFER = "NATIVE_TRANSFER",
  FT_TRANSFER = "FT_TRANSFER",
  NFT_TRANSFER = "NFT_TRANSFER",
}

export type FTTransferInfo = {
  transferType: TransferType.FT_TRANSFER;
  tokenAddress: string;
  tokenName: string;
  tokenSymbol: string;
  logoUri?: string;
  decimals: number;
  value: string;
};

export type NFTTransferInfo = {
  transferType: TransferType.NFT_TRANSFER;
  tokenAddress: string;
  tokenName: string;
  tokenSymbol: string;
  logoUri?: string;
  tokenId: string;
};

export type Creation = {
  transactionType: TransactionType.CREATION;
  creator: string;
};

export type Cancellation = {
  transactionType: TransactionType.CANCELLATION;
  isCancellation: Boolean;
};

export type MultiSigExecutionInfo = {
  orderCellBoc: string;
  queryId: string;
  expiredAt: number;
  confirmationsRequired: number;
  confirmationsSubmitted: number;
  confirmations: string[];
  executor?: string;
};

// on chain information
export type TransactionDetails = {
  from: string;
  to: string;

  // unit is gram
  value: string;

  // tx message data in HEX
  dataHex?: string;
  hash: string;
  executedAt: number;

  // unit is gram
  fee: string;
};

export type createTransferReq = {
  safeAddress: String;
  chainId: string;
  transfer: TransferReq;
  multiSigExecutionInfo: multiSigExecutionInfoReq;
};

export type TransferReq = {
  sender: String;
  recipient: String;
  direction: DirectionType;
  transferInfo: TransferInfoReq;
};

export type TransferInfoReq = {
  native?: NativeTransferInfoReq;
  ft?: FTTransferInfoReq;
  nft?: NFTTransferInfoReq;
};

export type FTTransferInfoReq = {
  transferType: TransferType.FT_TRANSFER;
  tokenAddress: String;
  tokenName: String;
  tokenSymbol: String;
  logoUri?: String;
  decimals: Number;
  value: String;
};

export type NFTTransferInfoReq = {
  transferType: TransferType.NFT_TRANSFER;
  tokenAddress: String;
  tokenName: String;
  tokenSymbol: String;
  logoUri?: String;
  tokenId: String;
};

type NativeTransferInfoReq = {
  transferType: TransferType.NATIVE_TRANSFER;
  value: String;
};

export type multiSigExecutionInfoReq = {
  orderCellBoc: String;
  queryId: String;
  expiredAt: Number;
  confirmationsRequired: Number;
  confirmationsSubmitted: Number;
  confirmations: String[];
  executor?: String;
};

export type signTransactionReq = {
  chainId: string;
  safeAddress: string;
  queryId: string;
  ownerAddress: string;
  signature: string;
};

export type mutationResBody = {
  success: boolean;
  error: resError;
};

type resError = {
  code: String;
  detail: String;
  extra: String;
};
