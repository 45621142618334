import { supportedChain } from "@/config/chains";
import { isClientSide } from "@/utils";
import { createContext, FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import { TonClient } from "ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";

export type TonState = TonClient | undefined;

const initialState: TonState = undefined;

export const TonContext = createContext<TonState>(initialState);

type TonProviderProps = {
  children: JSX.Element;
};

const TonProvider: FunctionComponent<TonProviderProps> = ({ children }) => {
  const [ton, setTon] = useState<TonClient>();

  useEffect(() => {
    const handler = async () => {
      if (!isClientSide()) {
        return undefined;
      }

      if (!supportedChain) {
        return undefined;
      }

      const endpoint = await getHttpEndpoint({
        network: supportedChain.network,
      });

      setTon(new TonClient({ endpoint }));
    };
    handler();
  }, []);

  return <TonContext.Provider value={ton}>{children}</TonContext.Provider>;
};

export default memo(TonProvider);
