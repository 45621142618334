import type { ReactElement } from "react";
import classnames from "classnames";
import { useAppSelector } from "@/store";
// import { selectChainById } from "@/store/chainsSlice";
import css from "./styles.module.css";
import { Skeleton } from "@mui/material";
import { CHAIN } from "@tonconnect/protocol";
import { ChainInfo } from "@/types/chainInfo";

type ChainIndicatorProps = {
  chainInfo?: ChainInfo;
  inline?: boolean;
  className?: string;
  renderWhiteSpaceIfNoChain?: boolean;
};

const ChainIndicator = ({
  chainInfo,
  className,
  inline = false,
  renderWhiteSpaceIfNoChain = true,
}: ChainIndicatorProps): ReactElement | null => {
  // const id = chainId || currentChainId
  const name = chainInfo ? chainInfo.chainName : "Unknown";
  const bg = chainInfo && chainInfo.chainId === CHAIN.MAINNET ? "#4d99eb" : "#dddddd";
  const textColor = chainInfo && chainInfo.chainId === CHAIN.MAINNET ? "white" : "black";
  // const chainConfig = useAppSelector((state) => selectChainById(state, id))

  const style = {
    backgroundColor: bg,
    color: textColor,
  };

  // if (!chainConfig?.chainName && !renderWhiteSpaceIfNoChain) return null

  return name ? (
    <span style={style} className={classnames(inline ? css.inlineIndicator : css.indicator, className)}>
      {name}
    </span>
  ) : (
    <Skeleton width="100%" height="22px" variant="rectangular" sx={{ flexShrink: 0 }} />
  );
};

export default ChainIndicator;
