export const AppRoutes = {
  "404": "/404",
  index: "/",
  newSafe: {
    create: "/new-safe/create",
    load: "/new-safe/load",
  },
  transactions: {
    tx: "/transactions/tx",
    queue: "/transactions/queue",
    index: "/transactions",
    history: "/transactions/history",
  },
  addressBook: "/address-book",
  balances: {
    index: "/balances",
  },
  settings: {
    index: "/settings",
    setup: "/settings/setup",
  },
};
