import "../styles/globals.css";
import type { AppProps } from "next/app";
import type { Theme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { SafeThemeProvider } from "@safe-global/safe-react-components";
import { ReactNode } from "react";
import PageLayout from "../components/common/PageLayout";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../store";
import useLoadableStores from "../hooks/useLoadableStores";
import Web3Provider from "@/components/Web3Provider";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from "@apollo/client";

const BASE_URL = process.env.NEXT_PUBLIC_GRAPHQL_URI;
const httpLink = new HttpLink({ uri: BASE_URL });

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${BASE_URL}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});
const client = new ApolloClient({
  link: ApolloLink.from([namedLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const AppProviders = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const themeMode = "light";

  return (
    <ApolloProvider client={client}>
      <SafeThemeProvider mode={themeMode}>
        {(safeTheme: Theme) => <ThemeProvider theme={safeTheme}>{children}</ThemeProvider>}
      </SafeThemeProvider>
    </ApolloProvider>
  );
};

const InitApp = () => {
  useLoadableStores();
  return null;
};

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Web3Provider>
          <AppProviders>
            <InitApp />
            <PageLayout pathname={router.pathname}>
              <Component {...pageProps} />
            </PageLayout>
          </AppProviders>
        </Web3Provider>
      </PersistGate>
    </Provider>
  );
}

export default MyApp;
