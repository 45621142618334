import { type ReactElement, useMemo } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import { Coins } from "ton3-core";

// import { formatCurrency } from '@/utils/formatNumber'
import { useSafeInfo, useSafeAddress, useBalances } from "@/hooks";
import { supportedChain } from "@/config/chains";
import SafeIcon from "@/components/common/SafeIcon";
import NewTxButton from "@/components/sidebar/NewTxButton";
// import { useAppSelector } from '@/store'
// import { selectCurrency } from '@/store/settingsSlice'

import css from "./styles.module.css";
// import QrIconBold from '@/public/images/sidebar/qr-bold.svg'
import CopyIconBold from "@/public/images/sidebar/copy-bold.svg";
import LinkIconBold from "@/public/images/sidebar/link-bold.svg";

import CopyButton from "@/components/common/CopyButton";
// import QrCodeButton from "../QrCodeButton";
import { SvgIcon } from "@mui/material";
import EthHashInfo from "@/components/common/EthHashInfo";
import { formatCurrency } from "@/utils/formatNumber";
const SafeHeader = (): ReactElement => {
  // const currency = useAppSelector(selectCurrency)
  const { balances } = useBalances();
  const { safe } = useSafeInfo();
  // const { threshold, owners } = safe
  // const settings = useAppSelector(selectSettings)

  const fiatTotal = useMemo(
    () => (balances.fiatTotal ? formatCurrency(balances.fiatTotal, "usd") : ""),
    [balances.fiatTotal],
  );

  const tonData = useMemo(
    () =>
      balances.assets.filter((asset) => {
        return asset.tokenInfo.tokenSymbol === "TON";
      }),
    [balances.assets],
  );
  const tonBalance = useMemo(
    () => (tonData[0] ? Coins.fromNano(tonData[0].balance, tonData[0].tokenInfo.decimals).toString() : undefined),
    [tonData],
  );
  const safeAddress: string = useSafeAddress();
  const threshold: number = safe.threshold;
  const owners = safe.owners.map((owner) => owner.address);
  const safeLoading: Boolean = false;
  const addressCopyText: string = safeAddress;
  const blockExplorerLink: string = supportedChain?.explorerUrl + "/address/" + addressCopyText;

  return (
    <div className={css.container}>
      <div className={css.info}>
        <div className={css.safe}>
          <div>
            {safeLoading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <SafeIcon address={safeAddress} threshold={threshold} owners={owners?.length} />
            )}
          </div>

          <div className={css.address}>
            {safeLoading ? (
              <Typography variant="body2">
                <Skeleton variant="text" width={86} />
              </Typography>
            ) : (
              <EthHashInfo address={safeAddress} shortAddress showAvatar={false} />
            )}

            <Typography variant="body2" fontWeight={700}>
              {fiatTotal || <Skeleton variant="text" width={60} />}
            </Typography>
          </div>
        </div>

        <div className={css.iconButtons}>
          {/* <QrCodeButton>
            <Tooltip title="Open QR code" placement="top">
              <IconButton className={css.iconButton}>
                <SvgIcon
                  component={QrIconBold}
                  inheritViewBox
                  color="primary"
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </QrCodeButton> */}

          <CopyButton text={addressCopyText} className={css.iconButton}>
            <SvgIcon component={CopyIconBold} inheritViewBox color="primary" fontSize="small" />
          </CopyButton>

          <Tooltip title="View on tonscan" placement="top">
            <IconButton className={css.iconButton} target="_blank" rel="noreferrer" href={blockExplorerLink}>
              <SvgIcon component={LinkIconBold} inheritViewBox fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <NewTxButton />
    </div>
  );
};

export default SafeHeader;
