import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { CHAIN } from "@tonconnect/protocol";
import type { RootState } from "@/store";

export enum PendingStatus {
  SIGNING = "SIGNING",
  SUBMITTING = "SUBMITTING",
  PROCESSING = "PROCESSING",
  INDEXING = "INDEXING",
}

type PendingTxsState =
  | {
      [txId: string]: {
        chainId: string;
        status: PendingStatus;
        txHash?: string;
        groupKey?: string;
        signerAddress?: string;
      };
    }
  | Record<string, never>;

const initialState: PendingTxsState = {};

export const pendingTxsSlice = createSlice({
  name: "pendingTxs",
  initialState,
  reducers: {
    setPendingTx: (
      state,
      action: PayloadAction<{
        chainId: CHAIN;
        txId: string;
        txHash?: string;
        groupKey?: string;
        status: PendingStatus;
        signerAddress?: string;
      }>,
    ) => {
      const { txId, ...pendingTx } = action.payload;
      state[txId] = pendingTx;
    },
    clearPendingTx: (state, action: PayloadAction<{ txId: string }>) => {
      delete state[action.payload.txId];
    },
  },
});

export const { setPendingTx, clearPendingTx } = pendingTxsSlice.actions;

export const selectPendingTxs = (state: RootState): PendingTxsState => {
  return state[pendingTxsSlice.name];
};

export const selectPendingTxById = createSelector(
  [selectPendingTxs, (_: RootState, txId: string) => txId],
  (pendingTxs, txId) => pendingTxs[txId],
);
