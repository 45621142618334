import { CHAIN } from "@tonconnect/protocol";
import { ChainInfo } from "@/types/chainInfo";

export const supportedChain: ChainInfo =
  process.env.NEXT_PUBLIC_NETWORK === "mainnet"
    ? {
        chainId: CHAIN.MAINNET,
        chainName: "Mainnet",
        network: "mainnet",
        explorerUrl: "https://tonscan.org",
        nativeCurrency: {
          name: "Toncoin",
          symbol: "TON",
          decimals: 9,
          logoUri: "",
        },
      }
    : {
        chainId: CHAIN.TESTNET,
        chainName: "Testnet",
        network: "testnet",
        explorerUrl: "https://testnet.tonscan.org",
        nativeCurrency: {
          name: "Toncoin",
          symbol: "TON",
          decimals: 9,
          logoUri: "",
        },
      };
