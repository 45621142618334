import { useState, type ReactElement } from "react";
import Header from "../Header";
import Footer from "../Footer";
import SideDrawer from "./SideDrawer";
import classnames from "classnames";
import css from "./styles.module.css";
import { useDebounce } from "@/hooks";
import SafeLoadingError from "../SafeLoadingError";

const isNoSidebarRoute = (pathname: string): boolean => {
  return ["/new-safe/create", "/new-safe/load", "/"].includes(pathname);
};

const PageLayout = ({ pathname, children }: { pathname: string; children: ReactElement }): ReactElement => {
  const noSidebar = isNoSidebarRoute(pathname);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true);
  let isAnimated = useDebounce(!noSidebar, 300);
  if (noSidebar) isAnimated = false;
  return (
    <>
      <header className={css.header}>
        <Header onMenuToggle={noSidebar ? undefined : setSidebarOpen} />
      </header>
      {!noSidebar && <SideDrawer isOpen={isSidebarOpen} onToggle={setSidebarOpen} />}
      <div
        className={classnames(css.main, {
          [css.mainNoSidebar]: noSidebar || !isSidebarOpen,
          [css.mainAnimated]: isAnimated,
        })}
      >
        <div className={css.content}>
          <SafeLoadingError>{children}</SafeLoadingError>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
