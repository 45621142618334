import { useEffect, useState, useContext } from "react";
import type { TransactionSummary } from "@safe-global/safe-gateway-typescript-sdk";
import { TonwebContext } from "@/components/TonwebProvider";
import { useSafeInfo, useTonBalance, useBalances } from "@/hooks";
import * as ton3 from "ton3-core";
import { toUserFriendlyAddress } from "@/utils/addresses";
import TonWeb from "tonweb";
import { getTransactionFee } from "@/utils/gas";
import { TransactionInfoType, TransactionTokenType } from "@safe-global/safe-gateway-typescript-sdk";

const useFee = (txSummary: TransactionSummary) => {
  const tonweb = useContext(TonwebContext);
  const tonBalance = useTonBalance();
  const { safe } = useSafeInfo();
  const { balances } = useBalances();
  const [fee, setFee] = useState<number>(-Infinity);
  const [isOutOfFee, setIsOutOfFee] = useState<boolean>(false);

  useEffect(() => {
    async function handler() {
      if (tonweb && safe) {
        const m = new ton3.Contracts.MessageExternalIn(
          {
            dest: new ton3.Address(safe.address),
          },
          {
            body: new ton3.Builder().cell(),
          },
        );
        const tonWebFee = await tonweb.provider.getEstimateFee({
          address: toUserFriendlyAddress(safe.address),
          body: TonWeb.utils.bytesToBase64(
            TonWeb.utils.hexToBytes(new ton3.BOC([new ton3.Builder().cell()]).toString()),
          ),
        });
        const storage: number = tonWebFee.source_fees.storage_fee;
        const nanoFee = await getTransactionFee([m.cell()], storage);
        const fee = nanoFee * Math.pow(10, -9);
        setFee(fee);
        if (txSummary.txInfo.type !== TransactionInfoType.TRANSFER) {
          return;
        }
        if (txSummary.txInfo.transferInfo.type === TransactionTokenType.NATIVE_COIN) {
          if (tonBalance && nanoFee + parseInt(txSummary.txInfo.transferInfo.value, 10) >= tonBalance)
            setIsOutOfFee(true);
          else {
            setIsOutOfFee(false);
          }
        } else if (txSummary.txInfo.transferInfo.type === TransactionTokenType.ERC20) {
          const tokenAddress = txSummary.txInfo.transferInfo.tokenAddress;
          const selectedToken = tokenAddress
            ? balances.assets.find((asset) => asset.tokenInfo.jettonMinterAddress === tokenAddress)
            : undefined;
          if (!selectedToken) return;

          const selectedTokenBalance = parseInt(selectedToken.balance, 10);
          const transferAmount = parseInt(txSummary.txInfo.transferInfo.value, 10);

          if (tonBalance && nanoFee + 0.045 >= tonBalance) setIsOutOfFee(true);
          else if (transferAmount > selectedTokenBalance) setIsOutOfFee(true);
          else {
            setIsOutOfFee(false);
          }
        }
      }
      return;
    }
    handler();
  }, [txSummary, tonweb, balances, safe, tonBalance]);

  return { fee, isOutOfFee };
};

export default useFee;
