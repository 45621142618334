import type { ReactElement } from "react";
import React from "react";
import { AppRoutes } from "@/config/routes";
import AssetsIcon from "@/public/images/sidebar/assets.svg";
import TransactionIcon from "@/public/images/sidebar/transactions.svg";
import ABIcon from "@/public/images/sidebar/address-book.svg";
import SettingsIcon from "@/public/images/sidebar/settings.svg";
import { SvgIcon } from "@mui/material";

export type NavItem = {
  label: string;
  icon?: ReactElement;
  href: string;
  badge?: boolean;
};

export const navItems: NavItem[] = [
  // {
  //   label: "Home",
  //   icon: <SvgIcon component={HomeIcon} inheritViewBox />,
  //   href: AppRoutes.dashboard,
  // },
  {
    label: "Assets",
    icon: <SvgIcon component={AssetsIcon} inheritViewBox />,
    href: AppRoutes.balances.index,
  },
  {
    label: "Transactions",
    icon: <SvgIcon component={TransactionIcon} inheritViewBox />,
    href: AppRoutes.transactions.history,
  },
  {
    label: "Address book",
    icon: <SvgIcon component={ABIcon} inheritViewBox />,
    href: AppRoutes.addressBook,
  },
  // {
  //   label: 'Apps',
  //   icon: <SvgIcon component={AppsIcon} inheritViewBox />,
  //   href: AppRoutes.apps.index,
  // },
  {
    label: "Settings",
    icon: <SvgIcon component={SettingsIcon} inheritViewBox />,
    href: AppRoutes.settings.setup,
  },
];

export const transactionNavItems = [
  {
    label: "Queue",
    href: AppRoutes.transactions.queue,
  },
  {
    label: "History",
    href: AppRoutes.transactions.history,
  },
];

export const balancesNavItems = [
  {
    label: "Tokens",
    href: AppRoutes.balances.index,
  },
  // {
  //   label: 'NFTs',
  //   href: AppRoutes.balances.nfts,
  // },
];

export const settingsNavItem = [
  {
    label: "Setup",
    href: AppRoutes.settings.setup,
  },
];
