import { useAppSelector } from "@/store";
import { selectHiddenTokensPerChain } from "@/store/settingsSlice";
import { supportedChain } from "@/config/chains";

const useHiddenTokens = () => {
  const { chainId } = supportedChain;
  return useAppSelector((state) => selectHiddenTokensPerChain(state, chainId));
};

export default useHiddenTokens;
