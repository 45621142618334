import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import type { LinkProps } from "next/link";
import Link from "next/link";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useAppSelector } from "@/store";
import { selectAddedSafes } from "@/store/addedSafesSlice";
import { useWallet } from "@/hooks";
import { isOwner } from "@/utils/transaction-guards";

import css from "./styles.module.css";

const SafeListItemSecondaryAction = ({
  chainId,
  address,
  onClick,
  href,
}: {
  chainId: string;
  address: string;
  onClick?: () => void;
  href?: LinkProps["href"];
}) => {
  const wallet = useWallet();
  const addedSafes = useAppSelector((state) => selectAddedSafes(state));

  // const isAddedSafeOwner = isOwner(addedSafes["-3"]?.[address]?.owners, wallet?.address);

  const isAdded = !!addedSafes[chainId]?.[address];
  const isAddedSafeOwner = true;

  if (!isAdded && href) {
    return (
      <Link href={href} passHref>
        <Button
          className={css.addButton}
          size="small"
          disableElevation
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
        >
          Add Safe
        </Button>
      </Link>
    );
  }

  if (!isAddedSafeOwner) {
    return (
      <Typography
        variant="body2"
        display="flex"
        alignItems="center"
        sx={({ palette }) => ({ color: palette.border.main })}
      >
        <VisibilityIcon fontSize="inherit" sx={{ marginRight: 1 }} /> Read only
      </Typography>
    );
  }
  return null;
};

export default SafeListItemSecondaryAction;
