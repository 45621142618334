import {
  combineReducers,
  configureStore,
  type ThunkAction,
  type AnyAction,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import storage from "./storage";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { safeInfoSlice } from "./safeInfoSlice";
import { pendingSafeSlice } from "./pendingSafeSlice";
import { sessionSlice } from "./sessionSlice";
import { txQueueSlice } from "./txQueueSlice";
import { pendingTxsSlice } from "./pendingTxsSlice";
import { addedSafesSlice } from "./addedSafesSlice";
import { txHistoryListener, txHistorySlice } from "./txHistorySlice";
import { addressBookSlice } from "./addressBookSlice";
import { balancesSlice } from "./balancesSlice";
import { settingsSlice } from "./settingsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  [safeInfoSlice.name]: safeInfoSlice.reducer,
  [addedSafesSlice.name]: addedSafesSlice.reducer,
  [addressBookSlice.name]: addressBookSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [txQueueSlice.name]: txQueueSlice.reducer,
  [txHistorySlice.name]: txHistorySlice.reducer,
  [pendingTxsSlice.name]: pendingTxsSlice.reducer,
  [pendingSafeSlice.name]: pendingSafeSlice.reducer,
  [balancesSlice.name]: balancesSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const listenerMiddlewareInstance = createListenerMiddleware<RootState>();
const middleware = [listenerMiddlewareInstance.middleware];
const listeners = [txHistoryListener];
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    listeners.forEach((listener) => listener(listenerMiddlewareInstance));
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware);
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
