import { useAppSelector } from "@/store";
import { selectTxHistory } from "@/store/txHistorySlice";
import { selectTxQueue } from "@/store/txQueueSlice";
import { toUserFriendlyAddress } from "@/utils/addresses";
import {
  ConflictType,
  DetailedExecutionInfoType,
  TransactionInfoType,
  TransactionListItemType,
  TransactionStatus,
  TransactionTokenType,
  TransferDirection,
  TransferInfo,
  MultisigExecutionDetails,
} from "@safe-global/safe-gateway-typescript-sdk";
import { useMemo } from "react";
import useSafeInfo from "./useSafeInfo";
import { TransactionType, TransferType } from "@/types/tx";
import { CompactTransactionListItem, CompactTransaction } from "@/types/transactions";

const useTxs = (isQueue: boolean) => {
  // The latest page of the queue is always in the store
  const txState = useAppSelector(isQueue ? selectTxQueue : selectTxHistory);
  const { safe } = useSafeInfo();

  const _tx = useMemo(
    () => ({
      page: {
        next: undefined,
        previous: undefined,
        results:
          !txState.data || txState.data.length === 0
            ? ([] as unknown as CompactTransactionListItem[])
            : txState.data
                .map((transaction, index): CompactTransaction => {
                  const timestamp = isQueue
                    ? transaction.summary.createdAt
                    : transaction.details
                    ? transaction.details.executedAt
                    : transaction.summary.createdAt;
                  let txInfo = undefined;
                  if (transaction.summary.transactionInfo.transactionType === TransactionType.TRANSFER) {
                    let transferInfo = undefined;
                    if (
                      transaction.summary.transactionInfo.transferInfo.transferType === TransferType.NATIVE_TRANSFER
                    ) {
                      transferInfo = {
                        type: TransactionTokenType.NATIVE_COIN,
                        value: transaction.summary.transactionInfo.transferInfo.value,
                      };
                    }
                    if (transaction.summary.transactionInfo.transferInfo.transferType === TransferType.FT_TRANSFER) {
                      transferInfo = {
                        type: TransactionTokenType.ERC20,
                        tokenAddress: transaction.summary.transactionInfo.transferInfo.tokenAddress,
                        tokenName: transaction.summary.transactionInfo.transferInfo.tokenName,
                        tokenSymbol: transaction.summary.transactionInfo.transferInfo.tokenSymbol,
                        logoUri: transaction.summary.transactionInfo.transferInfo.logoUri,
                        decimals: transaction.summary.transactionInfo.transferInfo.decimals,
                        value: transaction.summary.transactionInfo.transferInfo.value,
                      };
                    }
                    if (transaction.summary.transactionInfo.transferInfo.transferType === TransferType.NFT_TRANSFER) {
                      transferInfo = {
                        type: TransactionTokenType.ERC721,
                        tokenAddress: transaction.summary.transactionInfo.transferInfo.tokenAddress,
                        tokenId: transaction.summary.transactionInfo.transferInfo.tokenId,
                        tokenName: transaction.summary.transactionInfo.transferInfo.tokenName,
                        tokenSymbol: transaction.summary.transactionInfo.transferInfo.tokenSymbol,
                        logoUri: transaction.summary.transactionInfo.transferInfo.logoUri,
                      };
                    }
                    txInfo = {
                      type: TransactionInfoType.TRANSFER as TransactionInfoType.TRANSFER,
                      sender: {
                        value: transaction.summary.transactionInfo.sender
                          ? toUserFriendlyAddress(transaction.summary.transactionInfo.sender)
                          : "",
                      },
                      recipient: {
                        value: transaction.summary.transactionInfo.recipient,
                      },
                      direction: transaction.summary.transactionInfo.direction as unknown as TransferDirection,
                      transferInfo: transferInfo as TransferInfo,
                    };
                  }

                  if (transaction.summary.transactionInfo.transactionType === TransactionType.CREATION) {
                    txInfo = {
                      type: TransactionInfoType.CREATION as TransactionInfoType.CREATION,
                      creator: {
                        value: transaction.summary.transactionInfo.creator
                          ? toUserFriendlyAddress(transaction.summary.transactionInfo.creator)
                          : "",
                      },
                      transactionHash: transaction.details?.hash ?? "",
                    };
                  }

                  if (!txInfo) throw new Error("txInfo is not parsed");

                  let executionInfo = undefined;
                  let detailedExecutionInfo: MultisigExecutionDetails | undefined = undefined;
                  if (transaction.summary.multiSigExecutionInfo != null) {
                    executionInfo = {
                      type: DetailedExecutionInfoType.MULTISIG as DetailedExecutionInfoType.MULTISIG,
                      nonce: index,
                      confirmationsRequired: transaction.summary.multiSigExecutionInfo.confirmationsRequired,
                      confirmationsSubmitted: transaction.summary.multiSigExecutionInfo.confirmationsSubmitted,
                      missingSigners: safe.owners
                        .filter((_, idx) => transaction.summary.multiSigExecutionInfo!.confirmations[idx] === "")
                        .map((owner) => ({
                          value: owner.address ? toUserFriendlyAddress(owner.address) : "",
                        })),
                      queryId: transaction.summary.multiSigExecutionInfo.queryId,
                    };
                    detailedExecutionInfo = {
                      type: DetailedExecutionInfoType.MULTISIG,
                      submittedAt: transaction.summary.createdAt,
                      nonce: index,
                      safeTxGas: "",
                      baseGas: "",
                      gasPrice: "",
                      gasToken: "",
                      refundReceiver: {
                        value: transaction.summary.multiSigExecutionInfo?.executor
                          ? toUserFriendlyAddress(transaction.summary.multiSigExecutionInfo.executor)
                          : "",
                      },
                      safeTxHash: "",
                      trusted: true,
                      executor: transaction.summary.multiSigExecutionInfo?.executor
                        ? {
                            value: transaction.summary.multiSigExecutionInfo?.executor
                              ? toUserFriendlyAddress(transaction.summary.multiSigExecutionInfo?.executor)
                              : "",
                          }
                        : undefined,
                      signers: safe.owners.map((owner) => ({
                        value: toUserFriendlyAddress(owner.address),
                      })),
                      confirmationsRequired: transaction.summary.multiSigExecutionInfo?.confirmationsRequired,
                      confirmations: safe.owners.map((owner, idx) => ({
                        signer: {
                          value: toUserFriendlyAddress(owner.address),
                        },
                        signature: transaction.summary.multiSigExecutionInfo?.confirmations[idx],
                        submittedAt: transaction.summary.createdAt,
                      })),
                    };
                  }
                  return {
                    transaction: {
                      id: transaction.summary.multiSigExecutionInfo?.orderCellBoc ?? "",
                      timestamp,
                      txStatus: transaction.summary.status as unknown as TransactionStatus,
                      txInfo,
                      executionInfo,
                      safeAppInfo: undefined,
                    },
                    transactionDetails: {
                      safeAddress: safe.address ? toUserFriendlyAddress(safe.address) : "",
                      txId: transaction.summary.multiSigExecutionInfo?.queryId || "",
                      executedAt: transaction.details?.executedAt ? transaction.details?.executedAt : undefined,
                      txStatus: transaction.summary.status as unknown as TransactionStatus,
                      txInfo,
                      detailedExecutionInfo,
                      txHash: transaction.details ? transaction.details.hash : "",
                    },
                    conflictType: ConflictType.NONE,
                    type: TransactionListItemType.TRANSACTION as TransactionListItemType.TRANSACTION,
                  };
                })
                .sort((a, b) =>
                  isQueue
                    ? a.transaction.timestamp - b.transaction.timestamp
                    : b.transaction.timestamp - a.transaction.timestamp,
                ) ?? [],
      },
      error: txState.error,
      loading: txState.loading,
    }),
    [txState.data, txState.error, txState.loading, isQueue, safe.address, safe.owners],
  );

  // Return the new page or the stored page
  return _tx;
};

export default useTxs;
