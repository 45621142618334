import { useState, type ReactElement } from "react";
import { Divider, Drawer, IconButton } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useRouter } from "next/router";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
// import SafeList from '@/components/sidebar/SafeList'
import SidebarNavigation from "@/components/sidebar/SidebarNavigation";
import css from "./styles.module.css";
import SafeList from "../SafeList";

const Sidebar = (): ReactElement => {
  const router = useRouter();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const onDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
    <div className={css.container}>
      <div className={css.scroll}>
        <IconButton className={css.drawerButton} onClick={onDrawerToggle}>
          <ChevronRight />
        </IconButton>

        <>
          <SidebarHeader />
          <Divider />
          <SidebarNavigation />
        </>

        <div style={{ flexGrow: 1 }} />
      </div>

      <Drawer variant="temporary" anchor="left" open={isDrawerOpen} onClose={onDrawerToggle}>
        <div className={css.drawer}>
          <SafeList closeDrawer={() => setIsDrawerOpen(false)} />
        </div>
      </Drawer>
    </div>
  );
};

export default Sidebar;
