import { Tooltip as MuiTooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { isClientSide } from "@/utils";
import { useIsWrongChain } from "@/hooks";
import { supportedChain } from "@/config/chains";

const Tooltip = styled(({ className, ...props }) => <MuiTooltip {...props} arrow classes={{ popper: className }} />)(
  ({}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "transparent",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2F3033",
      marginTop: "10px !important",
      padding: "4px 8px",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#F1F0F4",
    },
  }),
);

const Switch = () => {
  const isWrongChain = useIsWrongChain();
  const isError = isClientSide() && isWrongChain;

  return (
    <Tooltip title={isError ? "Please connect to the appropriate TON network." : ""}>
      <div className="flex justify-center items-center px-4 py-[6px] border-solid border-[1px] border-[#73777F] rounded-[8px] text-base font-medium leading-[20px] tracking-[0.1px] hover:bg-[#49454F1F]/10">
        {isError && <ErrorIcon className="mr-2 text-[#FF5449]" />}
        {supportedChain.chainName}
      </div>
    </Tooltip>
  );
};

export default Switch;
