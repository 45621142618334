import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "@/store";

export type SettingsState = {
  hiddenTokens: {
    [chainId: string]: string[];
  };
};

const initialState: SettingsState = {
  hiddenTokens: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setHiddenTokensForChain: (state, { payload }: PayloadAction<{ chainId: string; assets: string[] }>) => {
      const { chainId, assets } = payload;
      state.hiddenTokens[chainId] = assets;
    },
  },
});

export const { setHiddenTokensForChain } = settingsSlice.actions;

export const selectSettings = (state: RootState): SettingsState => state[settingsSlice.name];

export const selectHiddenTokensPerChain = (state: RootState, chainId: string): string[] => {
  return state[settingsSlice.name].hiddenTokens?.[chainId] || [];
};
