import { useMemo } from "react";
import useBalances from "./useBalances";
import { Coins } from "ton3-core";

const useTonBalance = () => {
  const { balances } = useBalances();

  const tonData = useMemo(
    () =>
      balances.assets.filter((asset) => {
        return asset.tokenInfo.tokenSymbol === "TON";
      }),
    [balances.assets],
  );
  const tonBalance = useMemo(() => (tonData[0] ? parseInt(tonData[0].balance, 10) : undefined), [tonData]);

  return tonBalance;
};

export default useTonBalance;
