import { type ReactElement } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Link from "next/link";

import css from "./styles.module.css";
import SafeIcon from "@/components/common/SafeIcon";
import SafeListItemSecondaryAction from "@/components/sidebar/SafeListItemSecondaryAction";
import { AppRoutes } from "@/config/routes";
import Box from "@mui/material/Box";
import EthHashInfo from "@/components/common/EthHashInfo";
const SafeListItem = ({
  address,
  chainId,
  name,
  closeDrawer,
  shouldScrollToSafe = false,
  noActions = false,
  isAdded = false,
  ...rest
}: {
  address: string;
  chainId: string;
  name?: string;
  shouldScrollToSafe: boolean;
  closeDrawer?: () => void;
  threshold?: string | number;
  owners?: string | number;
  noActions?: boolean;
  isAdded?: boolean;
}): ReactElement => {
  // const chain = useAppSelector((state) => selectChainById(state, chainId))
  // const allAddressBooks = useAppSelector(selectAllAddressBooks)
  // const name = allAddressBooks[chainId]?.[address]
  // const chain = "testnet";
  // const shortName = chain || "";

  return (
    <ListItem
      className={css.container}
      disablePadding
      secondaryAction={
        noActions ? undefined : (
          <Box display="flex" alignItems="center" gap={1}>
            <SafeListItemSecondaryAction
              chainId={chainId}
              address={address}
              onClick={closeDrawer}
              href={{
                pathname: AppRoutes.newSafe.load,
                query: { chainId, address },
              }}
            />
          </Box>
        )
      }
    >
      <Link
        href={{
          pathname: AppRoutes.balances.index,
          query: { safe: `${address}`, chain: `${chainId}` },
        }}
        passHref
      >
        <ListItemButton key={address} onClick={closeDrawer} selected={false} className={css.safe}>
          <ListItemIcon>
            <SafeIcon address={address} {...rest} />
          </ListItemIcon>
          <ListItemText
            sx={noActions ? undefined : { pr: 10 }}
            primaryTypographyProps={{
              variant: "body2",
              component: "div",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            secondaryTypographyProps={{ component: "div", color: "primary" }}
            primary={name || ""}
            secondary={<EthHashInfo address={address} showAvatar={false} showName={false} prefix={""} />}
          />
        </ListItemButton>
      </Link>

      {/* <PendingActionButtons
        safeAddress={address}
        closeDrawer={closeDrawer}
        shortName={shortName}
        totalQueued={totalQueued}
        totalToSign={totalToSign}
      /> */}
    </ListItem>
  );
};

export default SafeListItem;
