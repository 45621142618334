import css from "./styles.module.css";

const Footer = () => {
  return (
    <footer className="flex justify-center">
      <p className="my-10">
        {/* Powered By{" "}
        <a href="https://fdc.ai" rel="noopener noreferrer nofollow" target="_blank" className="underline">
          FDC
        </a>{" "}
        &{" "}
        <a href="https://defits.capital/" rel="noopener noreferrer nofollow" target="_blank" className="underline">
          DeFITs{" "}
        </a> */}
      </p>
    </footer>
  );
};

export default Footer;
