import type { Dispatch, SetStateAction } from "react";
import { type ReactElement } from "react";
import { useRouter } from "next/router";
import { IconButton, Paper, SvgIcon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import tonkeyLogo from "@/public/tonkey-logo.svg";
import classnames from "classnames";
import css from "./styles.module.css";
import Link from "next/link";
import Switch from "./Switch";
import { AppRoutes } from "@/config/routes";
import { TonConnectButton } from "@tonconnect/ui-react";

type HeaderProps = {
  onMenuToggle?: Dispatch<SetStateAction<boolean>>;
};

const Header = ({ onMenuToggle }: HeaderProps): ReactElement => {
  const router = useRouter();
  // Logo link: if on Dashboard, link to Welcome, otherwise to the root (which redirects to either Dashboard or Welcome)
  // const logoHref =
  //   router.pathname === AppRoutes.transactions.history
  //     ? AppRoutes.index
  //     : router.pathname === AppRoutes.newSafe.create
  //     ? AppRoutes.index
  //     : AppRoutes.transactions.history;

  const handleMenuToggle = () => {
    if (onMenuToggle) {
      onMenuToggle((isOpen) => !isOpen);
    } else {
      router.push(AppRoutes.index);
    }
  };

  return (
    <Paper className={classnames(css.container, "py-3")}>
      <div className={classnames(css.element, css.menuButton)}>
        <IconButton onClick={handleMenuToggle} size="large" edge="start" color="default" aria-label="menu">
          <MenuIcon />
        </IconButton>
      </div>

      <div className={classnames(css.element, css.hideMobile, css.logo)}>
        <Link href={AppRoutes.index} passHref>
          <p className="flex items-center gap-2 cursor-pointer">
            <SvgIcon component={tonkeyLogo} inheritViewBox sx={{ width: "27.6px", height: "18px" }} />
            <p className="font-extrabold text-xl">Tonkey</p>
          </p>
        </Link>
      </div>
      <div className={css.element}>
        <TonConnectButton />
      </div>

      <div className={css.element}>
        <Switch />
      </div>
    </Paper>
  );
};

export default Header;
